export default (e) => {
    let errorKey
    switch (e?.response?.data?.type) {
        case 'conflict':
            errorKey = 'cancelSignatureConflict'
            break
        case 'unauthorized':
            errorKey = 'cancelSignatureUnauthorized'
            break
        default:
            errorKey = 'cancelContractSignature'
            break
    }
    return errorKey
}
