<template>
  <Card class="shadow-2">
    <template #title>
      <div class="flex justify-content-center align-items-center">
        <i class="fa fa-file-signature mr-2"></i>
        <div class="text-900 text-xl font-medium mr-4 ml-4 text-center">Contrats</div>
      </div>
    </template>
    <template #content>
      <div class="col-12">
        <DataTable :value="documents" responsiveLayout="scroll" class="p-datatable-sm" :loading="loading"
                   selectionMode="single" scrollable scroll-height="300px" @row-click="openContractPreview">
          <Column v-if="fields.includes('mission_client_name')" field="mission.clientContract.name" header="Mission" sortable sortField="mission.clientContract.name">
            <template #body="{data}">
              {{ data?.mission?.clientContract?.name?.toUpperCase() }}
            </template>
          </Column>
          <Column v-if="fields.includes('filename')" field="document.title" header="Nom du fichier" sortable sortField="document.title">
            <template #body="{data}">
              <span class="text-blue-500">
                {{ data?.document?.title }}
              </span>
            </template>
          </Column>
          <Column v-if="fields.includes('month_uploaded')" field="document.uploaded_on" header="Mois" sortable sortField="document.uploaded_on">
            <template #body="{data}">
              <span class="uppercase">
                <!--  todo: might use the metadata of the signature, what to do in Validated case  -->
                {{ new Date(data?.document?.uploaded_on).toLocaleDateString('fr-FR', {month: 'long'}) }}
              </span>
            </template>
          </Column>
          <Column v-if="fields.includes('year_uploaded')" field="document.uploaded_on" header="Année" sortable sortField="document.uploaded_on">
            <template #body="{data}">
              <span class="uppercase">
                {{ new Date(data?.document?.uploaded_on).toLocaleDateString('fr-FR', {year: 'numeric'}) }}
              </span>
            </template>
          </Column>
          <Column v-if="fields.includes('type')" field="type" header="Type" sortable sortField="type">
            <template #body="{data}">
              <span>
                {{
                  (data?.type === constants.DOCUMENT_TYPE.WORK_CONTRACT || data?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT)
                      ? 'Contrat' :
                      (data?.type === constants.DOCUMENT_TYPE.WORK_CONTRACT_AMENDMENT || data?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT_AMENDMENT)
                        ? 'Avenant' : ''
                }}
              </span>
            </template>
          </Column>
          <Column v-if="fields.includes('import_date')" field="document.uploaded_on" header="Date d'import" sortable sortField="document.uploaded_on">
            <template #body="{data}">
              <span class="uppercase">
                {{
                  new Date(data?.document?.uploaded_on).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                }}
              </span>
            </template>
          </Column>
          <Column v-if="fields.includes('initial_import_date')" field="date_created" header="Date initiale d'import" sortable sortField="date_created">
            <template #body="{data}">
              <span class="uppercase">
                {{
                  new Date(data?.date_created).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </Card>
  <dialog-file-preview :is-active="filePreviewIsActive" dialog-title="Prévisualisation" validationDialog closeButton modal
                       replace-file-button :file-id="contractFileId" :document-id="contractDocumentId"
                       @closePreview="resetDefaultFilePreview" @closeDialog="resetDefaultFilePreview"
                       @deleteFile="refreshContracts" @replace-file="refreshContracts"
  />
</template>

<script>
import DialogFilePreview from "@/components/DialogFilePreview.vue";
import {mapState} from "vuex";

export default {
  name: "ContractsAmendments",
  emits: ['refreshContracts'],
  components: {DialogFilePreview},
  props: {
    documents: {
      type: Array,
      required: true
    },
    deletableFiles: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      previewContractDialog: false,
      previewContractSrc: null,
      contractDocumentId: null,
      contractFileId: null,

      filePreviewIsActive: false,
      fileSource: null,
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    })
  },
  methods: {
    async openContractPreview(document) {
      this.contractDocumentId = document.data.id
      this.contractFileId = document.data.document.id
      this.filePreviewIsActive = true
    },
    resetDefaultFilePreview () {
      this.filePreviewIsActive = false
      this.contractDocumentId = null
      this.contractFileId = null
      this.fileSource = null
    },
    refreshContracts () {
      // todo: should update the component linked data instead of request again
      this.resetDefaultFilePreview()
      this.$emit('refreshContracts')
    }
  }
}
</script>

<style scoped>
.link-hover :hover {
  cursor: pointer;
}
</style>
