<template>
  <Dialog v-model:visible="dialogActive" class="p-4 documentDialog" :draggable="false" modal closable
          content-class="dialog-content" close-on-escape @hide="closeDialog">
    <template #header>
      <h3 class="mb-2">
        {{ $t(me.role.name === constants.ROLES.ADMIN ? 'adminMissionCreationTitle' : 'independentMissionCreationTitle') }}
      </h3>
    </template>
    <div class="mt-2" style="background-color: var(--surface-ground)">
      <fiche-mission
          v-if="me.role.name === constants.ROLES.ADMIN && openMissionForm"
          ref="missionCreationComponent"
          mission-creation-mode
          :independentId="independentId"
          :mission="previousMission"
          :copy-previous-mission="copyPreviousMission"
          @missionPayload="createMission"
      />
      <mission-form ref="missionForm" v-if="me.role.name === constants.ROLES.INDEPENDENT" mission-creation-mode :step="{ label: ''}" :mission="mission" />
      <div class="flex flex-row-reverse mt-2">
        <Button :label="$t('validate')" class="w-3" @click="validateMission"/>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Alert from "@/utils/Alert";
import FicheMission from "@/components/FicheMission";
import {mapState} from "vuex";
import MissionForm from "@/components/Mission/Prospect/MissionForm.vue";

export default {
  name: "DialogMissionCreation",
  emits: ["closeDialog", "missionCreated", "submitClient"],
  components: {MissionForm, FicheMission},
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    independentId: {
      type: String,
      default: ''
    },
    previousMission: {
      type: Object,
      default: null
    },
    copyPreviousMission: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    isActive(value) {
      this.dialogActive = value
      this.openMissionForm = !!value;
    }
  },
  data() {
    return {
      dialogActive: false,
      formValidated: false,
      openMissionForm: false,

      mission: {
        status: '',
        jsonMission: {
          jobTitle: null,
          jobBasicDescription: null,
          jobDescription: null,
          startingDate: null,
          initialDuration: null,
          initialEndingDate: null,
          renewal: null,
          trialPeriod: null,
          tjm: null,
          noticePeriod: {
            trial: {
              value: 0,
              unit: null
            },
            global: {
              value: 0,
              unit: null
            }
          }
        },
        jsonClientFinal: {
          fullName: null,
          address: null,
        },
        jsonProvider: {
          name: '',
          payment: {
            value: 0,
            unit: '€'
          }
        },
        jsonCost: {
          managementFee: 0,
          cap: 0,
          negotiatedRate: {
            value: 0,
            unit: ''
          },
          forfaitFacturationMode: null,
          teleworking: null,
          teleworkingRate: null,
          settlementPeriod: {
            value: '',
            unit: ''
          },
          solvency: {
            value: '',
            cap: ''
          },
          onCall: '',
          travelFee: ''
        },
        clientContract: null,
        contact: null,
        signatory: null,
        commercial: null,
        independent: {
          id: null,
          status: null,
          first_name: null,
          last_name: null,
          email: null,
          phone: null
        },
        notes: '',
        metadata: {
          clientContract: {
            name: null
          },
          clientContact: {}
        }
      },

      submitted: false
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants
    })
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    async validateMission() {
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        this.$refs.missionCreationComponent.sendMissionPayload()
      } else {
        const payload = await this.$refs.missionForm.onSubmit()
        if (payload.validate) {
          payload.mission.status = this.constants.MISSION_STATUS.PENDING_CONTRACT
          payload.mission.independent.id = this.independentId
          await this.createMission(payload.mission)
        }
      }
    },
    async createMission(payload) {
      this.$store.state.misc.loading = true
      const missionCreated = await this.$store.dispatch('mission/saveMission', payload)
      Alert.successMessage(this, 'saveMission')
      this.$store.state.misc.loading = false
      this.$emit('missionCreated', missionCreated)
    }
  }
}
</script>

<style scoped>
@import '../../assets/css/stepsComponents.css';
</style>
<style>
.documentDialog {
  width: 1500px !important;
  background-color: var(--surface-ground) !important;
}

.documentDialog .p-dialog-content {
  background-color: var(--surface-ground) !important;
}
</style>
