<template>
  <Dialog v-model:visible="dialogActive" :draggable="false" :modal="true" class="upload-contract-dialog" content-class="dialog-content" @hide="closeDialog">
    <template #header>
      <h3 class="mb-2">Importer un contrat</h3>
    </template>
    <div class="flex justify-content-center align-items-center" :style="`height:${iframeHeight}; max-height:1000px`">
      <FileUpload
          v-if="!contractPreviewSrc"
          id="commercialTemplateContractOwnGeneralConditionUpload"
          ref="logo"
          mode="basic"
          accept="application/pdf"
          uploadIcon="pi pi-upload"
          choose-label="Uploader un contrat"
          :showUploadButton="false"
          :maxFileSize="10000000"
          class="p-button-outlined"
          auto
          custom-upload
          @uploader="onUpload"
      />
      <div v-else class="flex align-items-center justify-content-center p-0 col-12">
        <iframe :style="`height:${iframeHeight}; max-height:1000px`" width="100%" :src="contractPreviewSrc"/>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse">
        <Button label="Valider" class="p-3 w-3" @click="prepareConfirmDialog"/>
        <Button label="Annuler" class="mr-2 w-2 p-button-outlined" @click="cancelContractCreation"/>
      </div>
    </template>
  </Dialog>
  <dialog-confirm
    :is-active="confirmDialog"
    :refuse-action="closeConfirmDialog"
    :validate-action="confirmDialogValidate"
    :title="confirmDialogTitle"
    :text-info="confirmDialogInfo"
    :text-question="confirmDialogQuestion"
  />
</template>

<script>
import { getBase64 } from "@/utils/Misc";
import DialogConfirm from "@/components/DialogConfirm";
import Alert from "@/utils/Alert";

export default {
  name: "DialogContractUpload",
  emits: ["closeDialog", "importContractFile"],
  components: {DialogConfirm},
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      contractPreviewSrc: null,
      importedContractFile: null,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},
    }
  },
  computed: {
    iframeHeight () {
      return this.calculateIframeHeight()
    }
  },
  created () {
    this.dialogActive = this.isActive
    this.contractPreviewSrc = null
    this.importedContractFile = null
  },
  methods: {
    closeDialog () {
      this.contractPreviewSrc = null
      this.$emit('closeDialog')
    },
    async onUpload (file) {
      // save as form for uploading in back
      const form = new FormData()
      form.append('filename', file.files[0].name)
      form.append('file', file.files[0])
      this.importedContractFile = form
      // save as a base64 string
      getBase64(file.files[0], (result) => {
        this.contractPreviewSrc = result
      });
    },
    cancelContractCreation () {
      this.contractPreviewSrc = null
      this.closeDialog()
    },
    prepareConfirmDialog () {
      if (!this.isFormValid()) {
        Alert.errorMessage(this, 'contractNotImported', {symbole: '(*)'})
        return
      }

      this.confirmDialogValidate = () => {
        this.submitContract()
        this.confirmDialog = false
      }
      this.confirmDialogTitle = 'Contrat importé manuellement'
      this.confirmDialogInfo = 'En validant, vous téléversez le contrat et validez la mission.'
      this.confirmDialogQuestion = 'Souhaitez-vous effectuer cette action ?'
      this.confirmDialog = true
    },
    isFormValid () {
      if (!this.importedContractFile) {
        return false
      }

      return true
    },
    async submitContract () {
      await this.prepareContract()
      this.closeDialog()
    },
    async prepareContract () {
      const payload = {
        form: this.importedContractFile,
      }

      this.$emit('importContractFile', payload)
    },
    calculateIframeHeight () {
      // todo: add breakpoint later on
      const height = window.innerHeight - 1000 + 35
      return `calc(100vh - ${height}px)`
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    }
  }
}
</script>

<style>
.upload-contract-dialog .p-dialog-content {
  width: 1000px !important;
  height: 1200px;
  background-color: var(--surface-ground) !important;
  overflow: hidden;
}
.upload-contract-dialog .p-dialog-footer {
  background-color: var(--surface-ground);
}

</style>
