<template>
  <div v-if="!loading" class="flex-container non-scrollable">
    <div id="mission-topbar" class="flex flex-column ">
      <div class="flex flex-column pl-0 pt-0 pr-0">
        <NameHeader :independent="independent" />
      </div>
      <div class="mt-4 surface-ground shadow-2">
        <div class="">
          <ul class=" p-0 m-0 list-none flex overflow-x-auto select-none">
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:border-primary-500': tab === 0, 'text-700 border-transparent': tab !== 0}" @click="changeTab(0)">
                <i class="fa fa-rocket mr-2"></i>
                <span class="font-bold"> {{missionSelected?.clientName }}</span>
              </a>
            </li>
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:border-primary-500': tab === 1, 'text-700 border-transparent': tab !== 1}" @click="changeTab( 1)">
                <i class="fa-solid fa-file-contract mr-2"></i>
                <span class="font-medium">Simulation</span>
              </a>
            </li>
            <li>
              <a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 p-ripple"
                 :class="{'border-primary-500 text-primary-500 hover:primary-blue-500': tab === 2, 'text-700 border-transparent': tab !== 2}" @click="changeTab( 2)">
                <i class="fa-solid fa-file-signature mr-2"></i>
                <span class="font-medium">Contrats / Avenants</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="p-3 flex flex-row xhl:px-5" :class="tab === 1 ? 'justify-content-end' : 'justify-content-between'">
          <div v-if="tab === 0" class="flex flex-column align-items-start m-0 p-0 gap-2 xxl:flex-row xxl:align-items-end">
            <div class="flex flex-column xfl:flex-row xfl:align-items-center ">
              <label class="text-lg font-bold line-height-3 mb-2 xfl:mb-0 xfl:mr-2">Mission</label>
              <Dropdown ref="missionDropdown" v-if="missionsIndependent" v-model="missionSelected" :options="missionsIndependent"
                        class="mb-2 xxl:mb-0 xxl:mr-3" style="width: auto;" @change="(value) => initMission(value.value.id, true)">
                <template #value="slotProps">
                <span>
                  {{ slotProps.value?.clientName }} -
                  {{ slotProps.value?.startingDate ? new Date(slotProps.value?.startingDate).toLocaleDateString() : 'Non définie' }}
                </span>
                </template>
                <template #option="slotProps">
                  <div>{{ slotProps.option?.clientName }} -
                    {{ slotProps.option?.startingDate ? new Date(slotProps.option?.startingDate).toLocaleDateString() : 'Non définie' }}
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="flex flex-row gap-2">
              <Button v-if="hasAccess($route.name, true)" icon="pi pi-copy" class="p-button-rounded w-full px-3 xxl:w-auto"
                      size="small" label="Dupliquer mission" @click="duplicateMission"/>
              <Button v-if="hasAccess($route.name, true)" :label="$t('createDraftMission')" icon="pi pi-plus"
                      class="p-button-rounded w-full xxl:w-auto"
                      @click="openCreateMission" size="small"/>
            </div>
          </div>
          <div v-if="tab === 0" class="flex align-items-end gap-2">
            <!--  todo: préciser que le document est entièrement signé ou non ?  -->
            <Button label="Voir le contrat" class="p-button-rounded" :disabled="!mission?.last_document_contract"
                    @click="downloadContractFile" size="small"/>
            <Button v-if="hasAccess($route.name, true) && mission?.status !== constants.MISSION_STATUS.ARCHIVED" size="small" label="Enregistrer" class="p-button-rounded" @click="save"/>
            <Button
                v-if="(mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.PENDING_SIGNATURE || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
                :disabled="!mission?.last_document_contract?.metadata?.signatureRequestId || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED"
                :label="mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED ? 'Annulation du contrat en cours' : 'Annuler le contrat en attente de signature'"
                class="p-button-rounded p-button-warning" size="small"
                @click="cancelContractSignatureConfirmation"
            />
            <Button v-if="hasAccess($route.name, true) && mission?.status === constants.MISSION_STATUS.COMPLETE &&
                    (mission?.last_document_contract?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT || mission?.last_document_contract?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT_AMENDMENT) &&
                    (mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.SIGNED || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.VALIDATED || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
                    label="Importer un avenant"
                    class="p-button-rounded"
                    @click="openAmendmentImportDialog"
                    size="small"
            />
            <Button
                v-if="hasAccess($route.name, true) && mission?.status === constants.MISSION_STATUS.COMPLETE &&
                  (mission?.last_document_contract?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT || mission?.last_document_contract?.type === constants.DOCUMENT_TYPE.MISSION_CONTRACT_AMENDMENT) &&
                  (mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.SIGNED || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.VALIDATED || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
                label="Générer un avenant"
                class="p-button-rounded"
                @click="openAmendmentGenerationDialog"
                size="small"
            />
          </div>
          <div v-else-if="hasAccess($route.name, true) && tab === 1" class="flex align-items-center gap-3">
            <Button label="Enregistrer" class="p-button-rounded"
                    :disabled="simulation.status !== constants.SIMULATION_STATUS.DRAFT || !canSaveSimulation"
                    @click="save"
                    size="small"/>
            <Button label="Envoyer" class="p-button-rounded"
                    :disabled="(simulation.status === constants.SIMULATION_STATUS.DRAFT || simulation.status === constants.SIMULATION_STATUS.TO_VALIDATE) || !canSendSimulation"
                    @click="sendSimulation"
                    size="small"/>
          </div>
        </div>
      </div>
    </div>

    <div class="scrollable mt-2" >
    <!--    <div id="scrolling-view">-->
      <!--          class="overflow-y-auto"-->
      <!--          style="height: inherit"-->

      <fiche-mission
          v-if="tab === 0"
          ref="missionPorte"
          :key="missionKeyComp"
          :mission="mission"
          :missions-independent="missionsIndependent"
          enable-amendment
          enable-mission-creation
          @on-mission-field-change="missionFieldChange"
          @force-mission-update="forceMissionUpdate"
          @changeMission="(value) => initMission(value, true)"
      />
      <fiche-simulation
          v-else-if="tab === 1"
          @canSave="(value) => canSaveSimulation = value"
          @canSend="(value) => canSendSimulation = value"
      />
      <mission-contracts-amendments v-else-if="tab === 2" :independent-id="independent.id"/>
      <div class="spacer"/>
    <!--    </div>-->
    </div>
    <!--  todo: when some free time, merge use the component dialogFilePreview and set it up properly  -->
    <dialog-contract-upload
        v-if="tab === 0"
        :is-active="amendmentImportDialog"
        @closeDialog="amendmentImportDialog = false"
        @importContractFile="importAmendment"
    />
    <dialog-contract-generation
        v-if="tab === 0"
        :is-active="amendmentGenerationDialog"
        :missionId="mission?.id"
        :mission-contract-metadata="mission?.contract_metadata"
        amendment
        @closeDialog="amendmentGenerationDialog = false"
        @contractGenerated="contractGenerated"
    />
    <dialog-prevent-amendment :is-active="isDialogAmendmentNeededActive" @importAmendment="preventAndImportAmendment"
                              @generateAmendment="preventAndGenerateAmendment" @postponeActionUntilLater="createAmendmentNotification"/>
    <dialog-mission-creation
        :previousMission="mission"
        :is-active="openMissionCreation"
        :copyPreviousMission="copyPreviousMission"
        :client-data="null"
        :independentId="independent.id"
        @closeDialog="closeMissionCreationDialog"
        @hide="closeMissionCreationDialog"
        @missionCreated="selectCreatedMission"
    />
    <dialog-confirm
        :is-active="confirmDialog"
        :refuse-action="closeConfirmDialog"
        :validate-action="confirmDialogValidate"
        :title="confirmDialogTitle"
        :text-info="confirmDialogInfo"
        :text-question="confirmDialogQuestion"
    />
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import FicheMission from "@/components/FicheMission";
import NameHeader from "@/components/NameHeader";
import FicheSimulation from "@/components/FicheSimulation";
import {mapGetters, mapState} from 'vuex'
import Alert from '@/utils/Alert'
import DialogContractGeneration from "@/components/Mission/DialogContractGeneration";
import DialogContractUpload from "@/components/Mission/DialogContractUpload";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import MissionContractsAmendments from "@/components/Contracts/MissionContractsAmendments.vue";
import DialogPreventAmendment from "@/components/DialogPreventAmendment.vue";
import DialogMissionCreation from "@/components/Mission/DialogMissionCreation";
import {setDateWithoutHours} from "@/utils/Date";
import DialogConfirm from "@/components/DialogConfirm.vue";
import getErrorKeySignature from "@/utils/GetErrorKeySignature";
export default {
  name: "FichePorte",
  components: {
    DialogConfirm,
    DialogPreventAmendment,
    MissionContractsAmendments,
    DialogContractUpload,
    DialogContractGeneration,
    FicheSimulation,
    NameHeader,
    FicheMission,
    DialogMissionCreation
  },
  data() {
    return {
      hasAccess: hasAccess,
      tab: 0,
      loading: true,
      independent: null,
      missionsIndependent: null,

      missionSelected: null,

      isAmendmentNeeded: false,

      isDialogAmendmentNeededActive: false,
      openMissionCreation: false,
      copyPreviousMission: false,
      cloneMission: null,

      openSendDocumentDialog: false,
      openContractCreationDialog: false,
      documentType: {},
      onSubmitCallBack: () => {
      },
      missionKeyComp: 0,

      amendmentGenerationDialog: false,
      amendmentImportDialog: false,

      canSaveSimulation: false,
      canSendSimulation: false,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},
    }
  },
  computed: {
    ...mapGetters({
      mission: 'mission/getMission'
    }),
    ...mapState({
      independent: state => state.independent.independent,
      simulation: state => state.simulation.simulation,
      constants: state => state.constants,
      SAVE_FLAG: state => state.misc.SAVE_FLAG
    })
  },
  watch: {
    async SAVE_FLAG(val) {
      if (val) {
        await this.save()
      }
    },
    async tab(val) {
      if (val === 0) {
        this.loading = true
        await this.$store.dispatch('mission/getOneMission', this.$route.params.idMission)
        if (!this.mission) {
          await this.$router.push({name: 'CommercialPortes'})
        }
        this.loading = false
      }
    },
    loading () {
      if (!this.loading) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  async created() {
    await this.initMission(this.$route.params.idMission)
    this.loading = false
  },
  methods: {
    async initMission(mission, forceCompUpdate = false) {
      // always needed in every page
      this.independent = await this.$store.dispatch('independent/getOneIndependent', this.$route.params.idPorte)

      if (this.$route.name === 'FicheMissionPorte' || this.$route.name === 'FicheSimulationPorte') {
        await this.$store.dispatch('commercial/getAllCommercials')
      }

      if (!this.independent) {
        await this.$router.push({name: 'CommercialPortes'})
      }

      if (this.$route.name === 'FicheMissionPorte') {
        this.mission = await this.$store.dispatch('mission/getOneMission', typeof mission === 'object' ? mission.id : mission)
        if (!this.mission) {
          await this.$router.push({name: 'CommercialPortes'})
        } else if ((this.mission.status === this.constants.MISSION_STATUS.DRAFT || this.mission.status === this.constants.MISSION_STATUS.INCOMPLETE
            || this.mission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT)) {
          await this.$router.push({name: 'CreationMissionPorte', params: { idMission: this.mission.id }})
        }
        await this.initMissionsIndependent()
      }

      if (this.$route.name === 'FicheContractsAmendments') {
        this.tab = 2
      }
      this.setMissionSelected()

      if (forceCompUpdate) {
        ++this.missionKeyComp
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    setMissionSelected () {
      this.missionSelected = JSON.parse(JSON.stringify({
        id: this.mission.id,
        clientName: this.mission?.clientContract?.name,
        startingDate: this.mission?.jsonMission?.startingDate ? setDateWithoutHours(this.mission?.jsonMission?.startingDate) : null,
      }))
    },
    async initMissionsIndependent() {
      this.missionsIndependent = (await this.$store.dispatch('mission/getCompletedMissionsByIndependent', {id: this.independent.id})).map((mission) => {
        return {
          id: mission.id,
          clientName: mission?.clientContract?.name,
          startingDate: mission?.jsonMission?.startingDate ? setDateWithoutHours(mission?.jsonMission?.startingDate) : null,
        }
      })
    },
    async initSimulationsIndependent() {
      if (!!this.$route.params.idSimulation && this.$route.params.idSimulation !== 'new') {
        await this.$store.dispatch('simulation/getSimulation', this.$route.params.idSimulation)
      } else {
        await this.$store.dispatch('simulation/getLastSimulation', this.$route.params.idPorte)
      }
      await this.$store.dispatch('simulation/getSimulationsIndependent', this.$route.params.idPorte)
    },
    async changeTab(tab) {
      if (tab === 0) {
        await this.initMissionsIndependent()
        await this.$router.push({
          name: 'FicheMissionPorte',
          params: {idPorte: this.$route.params.idPorte, idMission: this.mission?.id ?? this.missionsIndependent[0].id}
        })
        this.tab = 0
      } else if (tab === 1) {
        await this.initSimulationsIndependent()
        await this.$router.push({
          name: 'FicheSimulationPorte',
          params: {idPorte: this.$route.params.idPorte, idSimulation: this.simulation?.id ?? 'new'}
        })
        this.tab = 1
      } else if (tab === 2) {
        await this.$router.push({name: 'FicheContractsAmendments', params: {idPorte: this.$route.params.idPorte}})
        this.tab = 2
      }
    },
    async save() {
      this.$store.state.misc.loading = true
      switch (this.tab) {
        case 0:
          await this.saveMissionTab()
          break
        case 1:
          await this.saveSimulation()
          break
        default:
          break
      }
      this.$store.state.misc.loading = false
    },
    async saveMissionTab() {
      // prevent from modifying the current mission
      const missionPayload = JSON.parse(JSON.stringify(this.mission))
      missionPayload.jsonMission.startingDate = this.mission.jsonMission.startingDate.toISOString()
      missionPayload.jsonMission.initialEndingDate = this.mission.jsonMission.initialEndingDate.toISOString()

      await this.$store.dispatch('mission/saveMission', missionPayload)
      await this.$store.dispatch('misc/updateContentChanged', false)
      Alert.successMessage(this, 'saveMission')
      if (this.isAmendmentNeeded) {
        this.isDialogAmendmentNeededActive = true
      }
    },
    async openAmendmentImportDialog(autoSave = true, forceDialog = false) {
      if (autoSave) {
        await this.save()
      }
      if (!this.isDialogAmendmentNeededActive || forceDialog) {
        this.amendmentImportDialog = true
      }
    },
    async openAmendmentGenerationDialog(autoSave = true, forceDialog = false) {
      if (autoSave) {
        await this.save()
      }
      if (!this.isDialogAmendmentNeededActive || forceDialog) {
        this.amendmentGenerationDialog = true
      }
    },
    // eslint-disable-next-line no-unused-vars
    async missionFieldChange({mission, fieldOption}) {
      await this.$store.dispatch('misc/updateContentChanged', true)

      // force the amendment in case of saving
      this.isAmendmentNeeded = this.isAmendmentNeeded ? true : fieldOption.isAmendment
    },
    async downloadContractFile() {
      let fileO = this.mission.last_document_contract.document
      var file = await this.$store.dispatch('file/downloadFile', fileO)
      const blob = new Blob([new Uint8Array(file.data.data)], {type: file.mimetype})
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    prepareContract() {
      this.openContractCreationDialog = true
    },
    async sendSimulation() {
      try {
        await this.$store.dispatch('simulation/sendSimulation', {
          simulation: this.simulation,
          role: this.constants.ROLES.INDEPENDENT
        })
        Alert.successMessage(this, 'sendSimulation')
        this.$store.state.misc.loading = false
      } catch (error) {
        Alert.errorMessage(this, 'sendSimulation')
        this.$store.state.misc.loading = false
      }
    },
    async saveSimulation() {
      if (this.simulation.jsonClient && this.simulation.jsonClient.startingDate) {
        let date = new Date(this.simulation.jsonClient.startingDate)
        this.simulation.jsonClient.startingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
      if (this.simulation.jsonClient && this.simulation.jsonClient.endingDate) {
        let date = new Date(this.simulation.jsonClient.endingDate)
        this.simulation.jsonClient.endingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
      this.simulation.independent = { id: this.independent.id}
      await this.$store.dispatch('simulation/saveSimulation', this.simulation)
      Alert.successMessage(this, 'saveSimulation')
    },
    async importAmendment(payload) {
      await this.$store.dispatch('mission/importContract', {
        id: this.mission.id,
        form: payload.form,
        isAmendment: true
      })
      this.closePreventAmendmentDialog()
    },
    contractGenerated () {
      this.closePreventAmendmentDialog()
    },
    closePreventAmendmentDialog ()  {
      this.isAmendmentNeeded = false
      this.isDialogAmendmentNeededActive = false
    },
    async preventAndImportAmendment () {
      // by default disable the amendment needed
      await this.openAmendmentImportDialog(false, true)
    },
    async preventAndGenerateAmendment () {
      // by default disable the amendment needed
      await this.openAmendmentGenerationDialog(false, true)
    },
    async createAmendmentNotification () {
      // todo: create a notification which will be triggered in a few days
      this.closePreventAmendmentDialog()
    },
    openCreateMission() {
      this.openMissionCreation = true
    },
    duplicateMission() {
      this.copyPreviousMission = true
      process.nextTick(() => {
        this.openCreateMission()
      })
    },
    selectCreatedMission(payload) {
      // should redirect to the creation mission sets
      // todo: select the mission just created
      this.closeMissionCreationDialog()
      this.initMission(payload, true)
    },
    closeMissionCreationDialog () {
      this.copyPreviousMission = false
      this.openMissionCreation = false
    },
    forceMissionUpdate () {
      this.save()
    },
    cancelContractSignatureConfirmation () {
      if (hasAccess(this.$route.name, true)) {
        this.refuseAction = () => {
          this.confirmDialog = false
        }
        this.validateAction = async () => {
          await this.cancelContractSignature()
          this.confirmDialog = false
        }
        this.confirmDialogTitle =  'Confirmation Annulation'
        this.confirmDialogQuestion =  'Êtes vous sur d\'annuler la signature du contrat ?'
        this.confirmDialogInfo = 'Le contrat sera supprimé si vous validez et vous pourrez importer ou générer un nouveau contrat'
        this.confirmDialog = true
      }
    },
    async cancelContractSignature () {
      try {
        await this.$store.dispatch('mission/cancelMissionContractSignature', {
          signatureRequestId: this.mission.last_document_contract.metadata.signatureRequestId,
          documentId: this.mission.last_document_contract.id
        })
        await this.$store.dispatch('mission/getOneMission', this.mission.id, { root: true })
        Alert.successMessage(this, 'cancelContractSignature')
      } catch (e) {
        Alert.errorMessage(this, getErrorKeySignature(e))
      }
    }
  }
}
</script>

<style scoped>

</style>
