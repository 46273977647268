<template>
    <Dialog v-if="loaded" v-model:visible="dialogActive" :draggable="false" :modal="true"
            class="generate-contract-dialog w-10" content-class="dialog-content flex-container non-scrollable"
            @hide="closeDialog">
      <template #header>
        <h3 class="mb-2">{{ `Choix ${amendment ? 'de l\'avenant' : 'du contrat'}` }}</h3>
      </template>

      <div class="scrollable">
        <div class="flex m-auto pr-4 xxl:w-12">
          <div class="flex flex-column justify-content-center w-full xxl:py-6 xxl:flex-row">
            <div class="xxl:col-5">
              <div class="p-fluid overflow-auto p-2 pl-4">
                <Divider layout="horizontal" class="divider-contract-generation">
                  <b>Paramétrage du contrat</b>
                </Divider>

                <div class="flex flex-column">
                  <div class="field-row">
                    <label for="termsAndConditionsClientParticipation" class="block text-900 font-medium mb-2"> {{ $t('admin.params.commercial.termsAndConditionsClientParticipationLabel') }}</label>
                    <InputText id="termsAndConditionsClientParticipation" v-model="termsAndConditionsClientParticipation" type="text" class="w-5 p-3" @blur="generatePreviewContract"/>
                  </div>
                  <div class="field-row">
                    <label for="paymentMethod" class="block text-900 font-medium mb-2"> {{ $t('admin.params.commercial.paymentMethodLabel') }}</label>
                    <InputText id="paymentMethod" v-model="paymentMethod" type="text" class="w-5 p-3" @blur="generatePreviewContract"/>
                  </div>
                  <div class="field-row">
                    <label for="exceptionalExpenses" class="block text-900 font-medium mb-2"> {{ $t('admin.params.commercial.exceptionalExpensesLabel') }}</label>
                    <InputText id="exceptionalExpenses" v-model="exceptionalExpenses" type="text" class="w-5 p-3" @blur="generatePreviewContract"/>
                  </div>
                  <div class="field-row">
                    <label for="commercialCourt" class="block text-900 font-medium mb-2"> {{ $t('admin.params.commercial.commercialCourtLabel') }}</label>
                    <InputText id="commercialCourt" v-model="commercialCourt" type="text" class="w-5 p-3" @blur="generatePreviewContract"/>
                  </div>

                  <div class="field-row">
                    <label for="headerIntegration" class="text-900 font-medium mb-0 mr-4">
                      {{ `Intégrer l\'entête pour les pages générées automatiquement` }}
                    </label>
                    <Checkbox id="headerIntegration" v-model="headerIntegration" binary type="text" @change="generatePreviewContract"/>
                  </div>
                  <div class="field-row">
                    <label for="footerIntegration" class="text-900 font-medium mb-0 mr-4">
                      {{ `Intégrer le pied de page pour les pages générées automatiquement` }}
                    </label>
                    <Checkbox id="footerIntegration" v-model="footerIntegration" binary type="text" @change="generatePreviewContract"/>
                  </div>
                </div>

                <Divider layout="horizontal" class="divider-contract-generation">
                  <div class="field flex flex-row mb-0">
                    <label for="generateElectronicSignedContract" class="mb-0 mr-4 font-bold">
                      {{ `Souhaitez-vous signer ${amendment ? 'l\'avenant' : 'le contrat'} éléctroniquement ?` }}
                    </label>
                    <Checkbox id="generateElectronicSignedContract" v-model="generateElectronicSignedContract" binary type="text" @change="generatePreviewContract"/>
                  </div>
                </Divider>

                <div v-if="generateElectronicSignedContract" class="flex flex-column mt-1">
    <!--              <div class="flex flex-column">-->
    <!--                <label for="title" class="block text-900 font-medium mb-2"> {{ $t('documentEmailTitle') }} *</label>-->
    <!--                <InputText id="title" v-model="documentEmailTitle" type="text" class="w-full mb-3 p-3"/>-->
    <!--              </div>-->

                  <div class="flex flex-column">
                    <label for="subject" class="block text-900 font-medium mb-2"> {{ $t('documentEmailSubject') }} *</label>
                    <InputText id="subject" v-model="documentEmailSubject" type="text" class="w-full mb-3 p-3" />
                  </div>

                  <div class="flex flex-column">
                    <label for="message" class="block text-900 font-medium mb-2"> {{ $t('documentEmailMessage') }}</label>
                    <Textarea id="message" v-model="documentEmailMessage" rows="5" cols="30" />
                  </div>
                </div>

              </div>
            </div>

            <div class="hidden xxl:flex xxl:col-1">
              <Divider layout="vertical" class="divider-contract-generation m-0">
                <b>Votre contrat ></b>
              </Divider>
            </div>
            <div class="flex align-items-center justify-content-center p-0 pr-4 xxl:pr-4 xxl:col-6">
              <iframe :style="`height:${iframeHeight}; max-height:1000px`" width="100%" :src="contractPreviewSrc"/>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="flex flex-row-reverse">
          <Button :label="generateElectronicSignedContract ? 'Valider' : 'Télécharger'" class="p-3 w-3" @click="prepareConfirmDialog"/>
          <Button label="Annuler" class="mr-2 w-2 p-button-outlined" @click="cancelContractCreation"/>
        </div>
      </template>
    </Dialog>
    <dialog-confirm
        :is-active="confirmDialog"
        :refuse-action="closeConfirmDialog"
        :validate-action="confirmDialogValidate"
        :title="confirmDialogTitle"
        :text-info="confirmDialogInfo"
        :text-question="confirmDialogQuestion"
    />
</template>

<script>
import { getBase64 } from "@/utils/Misc";
import { mapState } from "vuex";
import Alert from "@/utils/Alert";
import DialogConfirm from "@/components/DialogConfirm";
export default {
  name: "DialogContractGeneration",
  components: {DialogConfirm},
  emits: ["closeDialog", "contractGenerated"],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    missionId: {
      type: String,
      default: '',
      required: true
    },
    missionContractMetadata: {
      type: Object,
      default: () => {
        return {}
      },
    },
    amendment: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async isActive (value) {
      if (value) {
        await this.initContract(true)
      }
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      contractPreviewSrc: null,
      generateElectronicSignedContract: false,
      loaded: false,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},

      // documentEmailTitle: '',
      documentEmailSubject: '',
      documentEmailMessage: '',

      termsAndConditionsClientParticipation: '',
      paymentMethod: '', // init vai settings
      exceptionalExpenses: '',
      rcpInsurance: '',
      financialGuarantee: '',
      commercialCourt: '',

      headerIntegration: true,
      footerIntegration: true,

      unSubmittedFile: false,
    }
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.Commercial
    }),
    iframeHeight () {
      return this.calculateIframeHeight()
    }
  },
  async created () {
    this.loaded = false
    this.dialogActive = this.isActive
    await this.initContract()
    this.loaded = true
  },
  methods: {
    async initContract (forcePreview = false) {
      await this.initSettings()
      if (forcePreview) {
        await this.generatePreviewContract()
      }
    },
    getCommercialSettings () {
      return {
        termsAndConditionsClientParticipation: this.termsAndConditionsClientParticipation,
        paymentMethod: this.paymentMethod,
        exceptionalExpenses: this.exceptionalExpenses,
        rcpInsurance: this.rcpInsurance,
        financialGuarantee: this.financialGuarantee,
        commercialCourt: this.commercialCourt
      }
    },
    async generatePreviewContract () {
      const contractBuffer = await this.$store.dispatch('mission/generateContract', {
        id: this.missionId,
        preview: true,
        electronicallySigned: this.generateElectronicSignedContract,
        commercialSettings: this.getCommercialSettings(),
        isAmendment: this.amendment,
        header: this.headerIntegration,
        footer: this.footerIntegration
      })
      getBase64(contractBuffer, (result) => {
        this.contractPreviewSrc = result
      })
    },
    prepareConfirmDialog () {
      if (!this.isFormValid()) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      if (this.generateElectronicSignedContract) {
        this.confirmDialogValidate = () => {
          this.submitContract()
          this.confirmDialog = false
        }
        this.confirmDialogTitle = 'Contrat signé éléctroniquement'
        this.confirmDialogInfo = 'En validant, vous envoyez ce contrat en signature éléctronique.'
        this.confirmDialogQuestion = 'Souhaitez-vous envoyer le contrat en signature éléctronique ?'
        this.confirmDialog = true
      } else {
        this.submitContract()
      }
    },
    async initSettings () {
      // force setting to be refreshed
      await this.$store.dispatch('misc/getAllSettings', {})
      this.termsAndConditionsClientParticipation = this.missionContractMetadata?.commercialSettings?.termsAndConditionsClientParticipation ?? this.settings?.contrat?.modalitesParticipationClient
      this.paymentMethod = this.missionContractMetadata?.commercialSettings?.paymentMethod ?? this.settings?.contrat?.moyenPaiement
      this.exceptionalExpenses = this.missionContractMetadata?.commercialSettings?.exceptionalExpenses ?? this.settings?.contrat?.fraisExceptionnels
      this.rcpInsurance = this.missionContractMetadata?.commercialSettings?.rcpInsurance ?? this.settings?.contrat?.assuranceRcp
      this.financialGuarantee = this.missionContractMetadata?.commercialSettings?.financialGuarantee ?? this.settings?.contrat?.garantieFinanciere
      this.commercialCourt = this.missionContractMetadata?.commercialSettings?.commercialCourt ?? this.settings?.contrat?.tribunalCommerce
    },
    cancelContractCreation () {
      this.closeDialog()
    },
    async submitContract () {
      await this.prepareContract()
      this.closeDialog()
    },
    isFormValid () {
      if (this.generateElectronicSignedContract) {
        // this.documentEmailTitle &&
        return this.documentEmailSubject
      }

      return true
    },
    async prepareContract () {
      const payload = {
        id: this.missionId,
        preview: !this.generateElectronicSignedContract,
        electronicallySigned: this.generateElectronicSignedContract,
        emailPayload: this.generateElectronicSignedContract ? {
          // documentEmailTitle: this.documentEmailTitle,
          documentEmailSubject: this.documentEmailSubject,
          documentEmailMessage: this.documentEmailMessage,
        } : undefined,
        forceDownload: !this.generateElectronicSignedContract,
        commercialSettings: this.getCommercialSettings(),
        isAmendment: this.amendment,
        header: this.headerIntegration,
        footer: this.footerIntegration
      }
      await this.$store.dispatch('mission/generateContract', payload)
      if (this.generateElectronicSignedContract) {
        this.$emit('contractGenerated')
      }
    },
    calculateIframeHeight () {
      // todo: add breakpoint later on
      const height = window.innerHeight - 1000 + 35
      return `calc(100vh - ${height}px)`
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    }
  }
}
</script>

<style>
.divider-contract-generation .p-divider-content {
  background-color: var(--surface-ground);
}
.generate-contract-dialog .p-dialog-content {
  height: 1200px;
  background-color: var(--surface-ground) !important;
  overflow: hidden;
}
.generate-contract-dialog .p-dialog-footer {
  background-color: var(--surface-ground);
}
.field-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:1rem
}
</style>

