<template>
  <Dialog
      v-model:visible="dialogActive"
      class="dialog-file-preview"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :closable="false"
      :draggable="false"
      modal
  >
    <template #header>
      <div style="width: 100%; text-align: center;">
        <i class="pi pi-check-circle text-green-500 text-4xl"></i>
        <h3 class="text-black-alpha-90">Les données modifiées nécessitent de créer un nouvel avenant</h3>
      </div>
    </template>
    <div class="text-green-500 mt-3" style="text-align: center; font-size: 1.2rem;">
      Vous pouvez importer ou générer un avenant pour garder le contrat à jour.
    </div>
    <div style="text-align: center;" class="mt-2 mb-3">
      Vous pouvez également le reporter à plus tard, un rappel vous sera transmis dans 5 jours.
    </div>
    <template #footer>
      <Button label="Importer un avenant" @click="importAmendment"/>
      <Button label="Générer un avenant" autofocus @click="generateAmendment"/>
      <Button label="Plus tard" class="p-button-warning" autofocus @click="postponeActionUntilLater"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DialogPreventAmendment",
  emits: ['importAmendment', 'generateAmendment', 'postponeActionUntilLater'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  methods: {
    importAmendment () {
      this.$emit('importAmendment')
    },
    generateAmendment () {
      this.$emit('generateAmendment')
    },
    postponeActionUntilLater () {
      this.$emit('postponeActionUntilLater')
    }
  }
}
</script>

<style scoped>

</style>
